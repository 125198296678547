<template>
  <div>
    <div
      :style="{
        position: isFixed ? 'fixed' : '',
      }"
      class="content"
      :class="{
        borderBottom: rollerSubscriptIndex == 0,
        borderBottom1: rollerSubscriptIndex == 233,
      }"
    >
      <div v-if="rollerSubscriptIndex == 0" class="logo"></div>
      <div v-if="rollerSubscriptIndex != 0" class="logo1"></div>
      <div
        :class="{
          style1: rollerSubscriptIndex != 0,
        }"
        class="text"
      >
        TCG HOME
      </div>
      <div class="classification">
        <div
          class="classification_Box"
          v-for="(item, index) in tabs"
          :key="index"
        >
          <div
            @click="selected(item, index)"
            :class="{
              classification_Box_selected: tabsIndex == index,
              style1: rollerSubscriptIndex != 0,
            }"
            class="classification_Box_top"
          >
            {{ item.name }}
          </div>
          <div
            v-if="tabsIndex == index"
            :style="{
              backgroundColor: rollerSubscriptIndex != 0 ? '#3D3D3D' : '',
            }"
            class="classification_Box_bottom"
          ></div>
        </div>
      </div>
      <div v-if="false" class="games">
        <div
          :class="{
            style1: rollerSubscriptIndex != 0,
          }"
          class="games_text"
        >
          PTCG之家
        </div>
        <div
          :style="{
            backgroundColor:
              rollerSubscriptIndex != 0 ? 'rgba(61,61,61,0.6)' : '',
          }"
          class="games_lint"
        ></div>
        <div
          :class="{
            style1: rollerSubscriptIndex != 0,
          }"
          class="games_text"
        >
          OPCG之家
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userInformation from "@/components/userInformation/index.vue";

export default {
  components: { userInformation },
  data() {
    return {
      //tabs数据
      tabs: [
        {
          name: "首页",
          path: "/reception/homePage",
        },
        {
          name: "关于我们",
          path: "/reception/AboutUs",
        },
      ],
      tabsIndex: 0,
      path: "",
    };
  },

  //计算属性
  computed: {},
  props: {
    rollerSubscriptIndex: {
      type: Number,
      default: 0,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  //监控data中的数据变化
  watch: {
    //监听路由变化
    $route() {
      //获取当前地址
      const path = this.$route.path;
      //判断path在tabs哪个里
      this.tabs.forEach((item, index) => {
        if (item.path == path) {
          this.tabsIndex = index;
        }
      });
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //获取当前地址
    const path = this.$route.path;
    this.path = path;
    console.log(path);
    //判断path在tabs哪个里
    this.tabs.forEach((item, index) => {
      if (item.path == path) {
        this.tabsIndex = index;
      }
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  //方法
  methods: {
    //选中方法
    selected(item, index) {
      //获取当前地址
      const path = this.$route.path;
      if (path == item.path) {
        return;
      }
      this.tabsIndex = index;
      //路由跳转
      this.$router.push({
        path: item.path,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 39px;
  width: 100%;
  height: 50px;
  // background-color: #1e8878;
  display: flex;
  align-items: center;
  // //相对定位
  z-index: 99;
  padding-bottom: 32px;
}
.borderBottom {
  //设置底部边框
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.borderBottom1 {
  //设置底部边框
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.logo {
  width: 50px;
  height: 50px;
  margin-left: 107px;
  background-image: url("@/assets/reception/1212.png");
}
.logo1 {
  width: 50px;
  height: 50px;
  margin-left: 107px;
  background-image: url("@/assets/reception/3455444.png");
}
.text {
  margin-left: 12px;
  width: 175px;
  height: 32px;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  margin-right: 160px;
}
.classification {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 32px;
  &_Box {
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    &_top {
      font-size: 18px;
      font-family: PINGFANG CUTI;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 18px;
    }
    &_selected {
      font-size: 24px !important;
      font-family: PINGFANG CUTI;
      font-weight: 400 !important;
      color: #ffffff !important;
      line-height: 24px !important;
    }
    &_bottom {
      width: 24px;
      height: 4px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
}
.games {
  margin-left: 700px;
  display: flex;
  align-items: center;
  &_text {
    font-size: 24px;
    font-family: PINGFANG CUTI;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
  }
  &_lint {
    margin-left: 32px;
    margin-right: 32px;
    width: 4px;
    height: 16px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
}
// 样式1
.style1 {
  color: #41526d !important;
}
.lint {
  height: 1px;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
