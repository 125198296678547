<template>
  <div>
    <!-- 内容 -->
    <div
      :style="{
        position: isAbsolute ? 'absolute' : '',
      }"
      class="content"
    >
      <div class="content_text">
        <p>
          Copyright © 1998-2023 Joyful and carefree All Rights Reserved..
          <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">
            闽ICP备2023012112号-1</a
          >
          | 开发者：福州乐无忧信息技术有限公司
        </p>
        <p>微博：@TCG HOME | 微信公众号：TCGHOME | 版本号： 1.0</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    isAbsolute: {
      type: Boolean,
      default: false,
    },
  },
  //计算属性
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  //方法
  methods: {
    ChangeRouter(index) {
      if (index === "homePage") {
        this.$router.push({ path: "/reception/homePage" });
      } else if (index === "AboutUs") {
        this.$router.push({ path: "/reception/AboutUs" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  border: 1px solid rgba(216, 216, 216, 0.6);
  padding: 24px 0px;
  width: 100%;
  height: 108px;
  // position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  &_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 860px;
    height: 60px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #41526d;
    line-height: 30px;
    p {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #41526d;
      line-height: 30px;
    }
  }
}
</style>
