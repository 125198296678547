import axios from "axios";
// import { MessageBox, Message } from "element-ui";
import { Message } from "element-ui";
// import store from "@/store";
// import { getToken } from "@/utils/auth";
import baseURL from "./backEndURL";
//引入element-ui的message组件
import qs from "qs";
// 创建axios实例
const service = axios.create({
  baseURL,
  timeout: 120000, // 请求超时
});

// 请求拦截
service.interceptors.request.use(
  config => {
    // 在发送请求之前做一些事情
    // const token = getToken();
    // 从缓存中获取userInfo里面有token
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      let token = userInfo.token;
      // 请求头携带token
      // config.headers.authorization = userInfo.token;
      //将token放入data中
      config.data = { ...config.data, token };
    }
    // if (store.getters.token) {
    //   //让每个请求都携带令牌
    //   //['X-Token']是自定义标头密钥
    //   //请根据实际情况修改
    //   config.headers["X-Token"] = getToken();
    // }

    //将请求qs序列化
    config.data = qs.stringify(config.data);

    return config;
  },
  error => {
    // 处理请求错误
    console.log(error); // 用于调试
    return Promise.reject(error);
  },
);

// 响应拦截器
service.interceptors.response.use(
  /**
   * 如果您想获取http信息，如标题或状态
   * 请返回响应=>响应
   */

  /**
   * 通过自定义代码确定请求状态
   * 这里只是一个例子
   * 您还可以通过HTTP状态代码来判断状态
   */
  response => {
    const res = response.data;
    // 如果自定义代码不是200，则判断为错误。
    if (res.code !== 200) {
      if (res.code == 555) {
        //提示登录
        Message({
          message: "请先登录",
          type: "error",
          duration: 5 * 1000,
        });
        return Promise.reject(new Error(res.message || "Error"));
      }
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  error => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  },
);

export default service;
