var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content",class:{
      borderBottom: _vm.rollerSubscriptIndex == 0,
      borderBottom1: _vm.rollerSubscriptIndex == 233,
    },style:({
      position: _vm.isFixed ? 'fixed' : '',
    })},[(_vm.rollerSubscriptIndex == 0)?_c('div',{staticClass:"logo"}):_vm._e(),(_vm.rollerSubscriptIndex != 0)?_c('div',{staticClass:"logo1"}):_vm._e(),_c('div',{staticClass:"text",class:{
        style1: _vm.rollerSubscriptIndex != 0,
      }},[_vm._v(" TCG HOME ")]),_c('div',{staticClass:"classification"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"classification_Box"},[_c('div',{staticClass:"classification_Box_top",class:{
            classification_Box_selected: _vm.tabsIndex == index,
            style1: _vm.rollerSubscriptIndex != 0,
          },on:{"click":function($event){return _vm.selected(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.tabsIndex == index)?_c('div',{staticClass:"classification_Box_bottom",style:({
            backgroundColor: _vm.rollerSubscriptIndex != 0 ? '#3D3D3D' : '',
          })}):_vm._e()])}),0),(false)?_c('div',{staticClass:"games"},[_c('div',{staticClass:"games_text",class:{
          style1: _vm.rollerSubscriptIndex != 0,
        }},[_vm._v(" PTCG之家 ")]),_c('div',{staticClass:"games_lint",style:({
          backgroundColor:
            _vm.rollerSubscriptIndex != 0 ? 'rgba(61,61,61,0.6)' : '',
        })}),_c('div',{staticClass:"games_text",class:{
          style1: _vm.rollerSubscriptIndex != 0,
        }},[_vm._v(" OPCG之家 ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }