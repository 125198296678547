import service from "@/utils/request";

export default {
  // 获取下载链接
  download: params => service.post("/appVersion/getLastVer", params),
};
// API.getRoutes().then((res) => {
//   let accessedRoutes;
//   const routes = res.data;
//   const asyncRoutes = getAsyncRoutes(routes.data); // 对路由格式进行处理
//   console.log(33, routes, asyncRoutes);
//   if (roles.includes("admin")) {
//     accessedRoutes = asyncRoutes || [];
//   } else {
//     // 这里是有做权限过滤的,如果不需要就不用
//     accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
//   }
//   commit("SET_ROUTES", accessedRoutes);
//   resolve(accessedRoutes);
// });
