import service from "@/utils/request";

export default {
  // 规则用语参照
  ruleLangListToShowUnion: params =>
    service.post("/ruleLang/listToShowUnion", params),
  //规则裁定分组列表
  ruleRulingGroupList: params =>
    service.post("/ruleQuAndAnGroup/listToShow", params),
  // 规则裁定列表
  ruleRulingList: params => service.post("/ruleQuAndAn/listToShow", params),
  // 提问
  ruleRulingQuestion: params => service.post("/ruleFeedBack/inquire", params),
  //修订记录
  ruleRulingRecord: params => service.post("/revision/listToShow", params),
};
// API.getRoutes().then((res) => {
//   let accessedRoutes;
//   const routes = res.data;
//   const asyncRoutes = getAsyncRoutes(routes.data); // 对路由格式进行处理
//   console.log(33, routes, asyncRoutes);
//   if (roles.includes("admin")) {
//     accessedRoutes = asyncRoutes || [];
//   } else {
//     // 这里是有做权限过滤的,如果不需要就不用
//     accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
//   }
//   commit("SET_ROUTES", accessedRoutes);
//   resolve(accessedRoutes);
// });
