import Vue from "vue";
import VueRouter from "vue-router";
// 容器页
import reception from "@/views/reception/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/reception/homePage",
  },
  {
    name: "reception",
    path: "/reception",
    component: reception,
    children: [
      //首页
      {
        path: "homePage",
        component: () => import("@/views/reception/homePage/index.vue"),
      },
      // 关于我们
      {
        path: "AboutUs",
        component: () => import("@/views/reception/AboutUs/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
// 路由守卫
router.beforeEach((to, from, next) => {
  //正则判断设备
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
    navigator.userAgent,
  );
  if (isMobile) {
    //跳转到百度
    window.location.href = "http://m.chnzxl.com/";
  } else {
    next();
  }

  console.log(222, isMobile);
});
export default router;
