import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import animated from "animate.css";
//挂载方法集合
import methodSet from "@/utils/publicMethods.js";
Vue.prototype.$methodSet = methodSet;
const imgUrl = "https://kaletcg.oss-cn-hangzhou.aliyuncs.com";
Vue.prototype.$imgUrl = imgUrl;
// Vue.use(animated);
Vue.use(ElementUI);
Vue.config.productionTip = false;
// 导入flexible
// import "amfe-flexible";
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
