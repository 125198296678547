// const baseURL = "http://192.168.0.38:8181/";
// const baseURL = "http://192.168.0.9:8181/";
const baseURL = "https://j.chnzxl.com/bkm";

// const baseURL = "https://j.kaletcg.com/kale/";

// export default process.env.NODE_ENV === "production"
//   ? "https://j.kaletcg.com/kale/"
//   : baseURL;
export default process.env.NODE_ENV === "production"
  ? "https://j.chnzxl.com/bkm"
  : baseURL;
