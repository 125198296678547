<template>
  <div>
    <!-- 顶部NavMenu -->
    <!-- <NavMenu class="NavMenu"></NavMenu> -->
    <!-- 中部内容盒子 -->
    <div class="content">
      <router-view></router-view>
    </div>
    <!-- 底部bottomBar -->
    <!-- <bottomBar></bottomBar> -->
  </div>
</template>

<script>
// import API from "@/api/index.js";
import NavMenu from "@/components/NavMenu/index.vue";
import bottomBar from "@/components/bottomBar/index.vue";

export default {
  components: { NavMenu, bottomBar },
  data() {
    return {
      //当前路由
      currentRoute: "",
    };
  },

  //计算属性
  computed: {},

  //监控data中的数据变化
  watch: {
    //监听路由地址
    $route(to) {
      this.currentRoute = to.path;
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //监听路由地址
    this.currentRoute = this.$route.path;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  //方法
  methods: {},
};
</script>
<style lang="scss" scoped></style>
